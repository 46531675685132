import axios from 'axios';
import { toast } from 'react-toastify';

import {reset} from '../store/authSlice'
import { toastOptions } from 'src/Commonfunc';

let store
export const injectStore = _store => {
    store = _store
}

axios.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token'); // Assuming you store the token in localStorage
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );


axios.interceptors.response.use(
    response => {
        return response
    },
    function (error) {
        if(error.response.status==401){
            toast.error('Session Expired Please login again', toastOptions)
            localStorage.removeItem("token")
            localStorage.removeItem("settings")
            localStorage.removeItem("user")
            store.dispatch(reset())
            window.location.reload(false)
        }
        return Promise.reject(error);
    }
);


const apiCall = (config)=> {
    return new Promise((resolve, reject)=>{
        axios(config).then((response)=>{
            resolve(response)
        }).catch(err=>{
            reject(err)
        })
    })
}



export default apiCall;
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    sidebarShow: true,
}

export const appHeaderSlice = createSlice({
    name: 'sidebarShow',
    initialState,
    reducers:{
        setSidebar: (state, action) => {
            state.sidebarShow = action.payload
        }
    }
})
export const { setSidebar } = appHeaderSlice.actions;
export default appHeaderSlice.reducer;
import * as moment from 'moment'
export const formatDate = (date)=>{
    const currDatetime = moment(new Date())
    let diff = currDatetime.diff(date, 'minutes')
    let dateString = ''
    if(diff < 1){
        dateString = "Just now"
    }else if(diff < 60){
        dateString = diff + " minutes ago"
    }else if(diff > 60){
        let hours = Math.floor(diff / 60)
        dateString = hours + " hours ago"
        if(hours > 24 && hours <= 48){
            let days = Math.floor(hours /24)
            if(days==1){
                dateString = days + " Day ago"    
            }else{
                dateString = days + " Days ago"
            }
        }else if(hours > 48){
            dateString = date
        }
    }else {
        dateString = date
    }
    return dateString
}

export const getColor = (status, due_date, done_date)=>{
    const currDate  = new Date(moment().format("MM/DD/YYYY HH:mm"))
    let color = ''
    if(status==1){
        const diffTime = currDate - due_date;
        const diffDays = diffTime / (1000 * 60 * 60);
        
        if(diffDays>=1){
            color = 'danger'
        }
    }else if(status==2){
        const diffTime = done_date - due_date;
        const diffDays = diffTime / (1000 * 60 * 60);
        color = 'warning'
        if(diffDays>=1){
            color = 'danger'
        }
    }else if(status==5){
        color = 'danger'
    }else if(status==6){
        color = 'success'
    }
    return color
}

export const toastOptions = ()=>{
    return {
        position: "top-right",
        autoClose: 2500,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
    }
}
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isAuth: false,
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers:{
        setAuth: (state, action) => {
            const { user } = action.payload;
            state.user = user;
            state.isAuth = true
        },
        reset:(state)=>{
            state.user = null
        }
    }
})
export const { setAuth, reset } = authSlice.actions;
export default authSlice.reducer;
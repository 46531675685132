import { configureStore } from '@reduxjs/toolkit'
import auth  from './authSlice'
import sidebarShow from './appHeaderSlice'


export const store = configureStore({
    reducer:{
        auth,
        sidebarShow
    }
})
export default store